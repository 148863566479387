<template>
  <DefultTempalte
    v-loading.lock="loadingSubmit"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-card">
      <h1>Lead Scoring</h1>
      <el-form ref="form" v-model="form" label-position="top">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="เลขนิติบุคคล">
              <el-radio-group v-model="form.type" @change="selectTypeImport">
                <el-radio :label="1">Input</el-radio>
                <el-radio :label="2">Import</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="box-card mg-t-5">
      <div v-if="form.type == 1">
        <h1>Input เลขนิติบุคคล</h1>
        <el-form v-model="formAdd" class="mg-t-4">
          <el-row :gutter="0">
            <el-col :span="24" :sm="24" :md="24">
              <el-row :gutter="24">
                <el-col :span="24" :sm="12" :md="10" :xl="8">
                  <el-col
                    :span="24"
                    v-for="(form, indexF) in formAdd"
                    :key="indexF"
                  >
                    <el-row :gutter="20">
                      <el-col :span="24">
                        <el-form-item label="เลขนิติบุคคล">
                          <el-input
                            v-model="form.juristicId"
                            @keypress.native="isNumber($event)"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="24" v-if="!statusCalLead">
                    <p>
                      <span
                        class="font-20 color-main font-weight-600 cursor-pointer"
                        @click="addJuristic"
                      >
                        + เพิ่มเลขนิติบุคคล
                      </span>
                    </p>
                  </el-col>
                </el-col>
                <el-col
                  :span="24"
                  :sm="12"
                  :md="14"
                  :xl="10"
                  v-if="statusCalLead"
                >
                  <el-col
                    :span="24"
                    v-for="(result, indexR) in resultLead"
                    :key="indexR"
                  >
                    <el-row :gutter="20">
                      <el-col :span="8" :md="8" :sm="24">
                        <el-form-item
                          label="ชื่อบริษัท"
                          disabled="true"
                          class="messageShow"
                          :class="result.status == true ? 'normal' : 'error'"
                        >
                          <el-input
                            :disabled="true"
                            v-model="result.companyName"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" :md="8" :sm="24">
                        <el-form-item
                          label="สถานะเกรด"
                          disabled="true"
                          class="messageShow"
                          :class="result.status == true ? 'normal' : 'error'"
                        >
                          <el-input
                            :disabled="true"
                            v-model="result.leadStatus"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                      <el-col :span="8" :md="8" :sm="24">
                        <el-form-item
                          label="ที่ตั้งสถานประกอบการ"
                          class="messageShow"
                        >
                          <el-input
                            :disabled="true"
                            v-model="result.location"
                          ></el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="24" :sm="24" :md="24">
              <el-row :gutter="0">
                <el-col :span="24" :md="12" class="text-center">
                  <a @click="resetFormAdd" class="font-18 cursor-pointer"
                    >ล้างข้อมูล</a
                  >
                  <el-button
                    type="primary"
                    size="default"
                    class="mg-l-1"
                    :loading="loadingCalLead"
                    @click="submitCalLead"
                    :disabled="statusCalLead == true ? true : false"
                    >คำนวณ Lead</el-button
                  >
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div v-else-if="form.type == 2">
        <div class="import-lead-scoring-wrapper">
          <h1>Import เลขนิติบุคคล</h1>
          <div class="pt-2">
            <el-button
              type="primary"
              size="default"
              @click="redirectToQueueList"
              >รายการ Import Data</el-button
            >
          </div>
        </div>
        <div>
          <p class="font-20 mg-b-6">
            Import CSV, Excel File
            <!-- <span class="mg-l-5">
              <el-tooltip
                placement="bottom-start"
                style="font-family: 'Font Awesome 5 Free' !important"
              >
                <div slot="content">
                  <span>
                    การตั้งชื่อไฟล์ในการ Import <br /><br />
                    <span>
                      1. ข้อมูลเลขนิติที่ต้องการ Lead Scoring ตั้งชื่อไฟล์ :
                      lead_scoring.xlsx
                    </span>
                  </span>
                </div>
                <i class="fas fa-question-circle"></i>
              </el-tooltip>
              การตั้งชื่อไฟล์
            </span> -->
          </p>
          <el-row :gutter="20">
            <el-col :span="12" :offset="0">
              <el-upload
                multiple
                action="#"
                :auto-upload="false"
                :on-change="(file, fileList) => uploadDocuments(file, fileList)"
                :on-remove="handleRemoveDocuments"
                :limit="1"
                :file-list="fileList"
                :on-exceed="handleExceed"
              >
                <el-button type="info" size="default" class="w-100"
                  >แนบไฟล์</el-button
                >
              </el-upload>
            </el-col>
          </el-row>
          <el-row :gutter="20" class="mg-t-3">
            <el-col :span="12" :offset="0" class="text-right">
              <a @click="resetForm" class="font-18 cursor-pointer">ยกเลิก</a>
              <el-button
                type="primary"
                size="default"
                class="mg-l-1"
                :loading="loadingSubmit"
                @click="submitImportData"
                :disabled="form.file.length == 0"
                >นำเข้าข้อมูล</el-button
              >
            </el-col>
          </el-row>
        </div>
        <el-form v-model="formdata" class="mg-t-4">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-row :gutter="20">
                <el-col :span="24" :md="6">
                  <el-form-item label="ค้นหาเลขนิติบุคคล">
                    <el-input
                      @input="searchData"
                      v-model="formdata.juristicId"
                      placeholder="กรอกเลขนิติบุคคล"
                      clearable
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="24" :md="6">
                  <el-form-item label="ค้นหา Lead">
                    <el-select
                      clearable
                      filterable
                      v-model="formdata.lead"
                      placeholder="เลือก Lead"
                      @change="searchData"
                    >
                      <el-option
                        v-for="item in optionLeadGrade"
                        :key="item.gradeName"
                        :label="item.gradeName"
                        :value="item.gradeName"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form>

        <div class="is-flex js-between ai-center">
          <h1>แสดงข้อมูล Lead Scoring</h1>

          <div class="is-flex ai-center">
            <el-button size="default" @click="downloadTemplate" class="btn-sync"
              >Download template</el-button
            >
            <!-- <el-button
              type="primary"
              size="default"
              @click="deleteAllLeadScoring"
              class="default-download"
              >ล้างข้อมูล</el-button
            > -->
            <el-button
              type="primary"
              size="default"
              @click="exportLeadScoring"
              class="default-download"
              >Export Data</el-button
            >
          </div>
        </div>

        <div class="tab-dashboard size-tab el-tabs el-tabs--card el-tabs--top">
          <el-tabs v-model="activeName" type="card">
            <el-tab-pane name="lead" label="แสดงข้อมูล Lead Scoring">
              <!-- <p class="text-right mg-b-7 color-75">
                <span v-if="fetchUpload == true">
                  <i class="el-icon-loading mg-r-7"></i>
                  กำลัง Upload File</span
                >
                <span
                  v-else-if="
                    fetchUpload == false &&
                    formatReport.updatedAt != '' &&
                    dataLead.length > 0
                  "
                >
                  อัปเดตเมื่อ
                  {{ formatReport.updatedAt | formatDateReport }}
                </span>
              </p> -->
            </el-tab-pane>
          </el-tabs>

          <!-- alert status upload queue -->
          <el-alert
            v-if="hasQueue"
            class="alert-queue-running"
            :closable="false"
            type=""
            effect="dark"
            :center="true"
          >
            <template>
              <h1 class="text-center">
                <i class="fas fa-spinner"></i> กำลังคำนวณ Lead Scoring
              </h1>
            </template>
          </el-alert>
          <!-- end alert status upload queue -->
          <!-- update lasted -->
          <div class="is-flex js-end mg-t-7 mg-b-7">
            <p class="text-right mg-b-7 color-75">
              <span v-if="fetchUpload == true">
                <i class="el-icon-loading mg-r-7"></i>
                กำลัง Upload File</span
              >
              <span
                v-else-if="
                  fetchUpload == false &&
                  formatReport.updatedAt != '' &&
                  dataLead.length > 0
                "
              >
                อัปเดตเมื่อ
                {{ formatReport.updatedAt | formatDateReport }}
              </span>
            </p>
          </div>
          <!-- end update lasted -->

          <TableLeadScoring
            :dataLead="dataLead"
            :loading="loading"
            :totalNow="totalNow"
            :pageNow="pageNow"
            :limit="limit"
            @changePage="changePage"
            @handleSizeChange="handleSizeChange"
          />
        </div>
      </div>
    </div>
  </DefultTempalte>
</template>
<script>
import DefultTempalte from "@/template/DefaultTemplate";
import TableLeadScoring from "@/components/table/TableLeadScoring";
import { HTTP } from "@/service/axios";
import { HTTPUPLOAD } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: {
    DefultTempalte,
    TableLeadScoring,
  },
  data() {
    return {
      urlBase: process.env.VUE_APP_BASE_URL,
      fileList: [],
      form: {
        type: null,
        file: [],
      },
      formdata: {
        lead: "",
        juristicId: "",
      },
      formAdd: [
        {
          juristicId: "",
        },
      ],
      pageNow: 1,
      totalNow: 10,
      activeName: "lead",
      loading: false,
      loadingSubmit: false,
      loadingCalLead: false,
      statusCalLead: false,
      resultLead: [],
      dataLead: [],
      limit: 10,
      fetchUpload: null,
      optionLeadGrade: [],
      formatReport: {},
      hasQueue: false,
      queueCount: 0,
    };
  },
  computed: {
    ...mapState({
      _token: (state) => state._token,
      total: (state) => state.leadscoring.total,
    }),
  },
  mounted() {
    this.getQueue();
    this.getScoringGrade();
  },
  methods: {
    redirectToQueueList() {
      this.$router.push("/lead-scoring/queue");
    },
    async getQueue() {
      let res = await this.$store.dispatch("leadscoring/checkQueue", null);
      if (res.success) {
        this.hasQueue = true;
        this.queueCount =
          res.count != undefined && res.count != null ? res.count : 0;
        return;
      }

      this.hasQueue = false;
      return;
    },
    getScoringGrade() {
      HTTP.get(`lead/scoring/grade`)
        .then((res) => {
          if (res.data.success) {
            this.optionLeadGrade = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    scoringQueue() {
      HTTP.get(`lead/scoring/queue`)
        .then((res) => {
          if (res.data.success) {
            this.fetchUpload = res.data.obj.status;
            this.formatReport = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    getLeadSocring(type) {
      this.loading = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(
        `lead/scoring?page=${this.pageNow}&limit=${this.limit}&juristicId=${this.formdata.juristicId}&lead=${this.formdata.lead}`
      )
        .then((res) => {
          if (res.data.success) {
            this.dataLead = res.data.obj.data;
            this.totalNow = res.data.obj.totalPage;
            let time = 0;
            if (type == "byDownload") {
              time = 1500;
            }
            setTimeout(() => {
              this.scoringQueue();
            }, time);
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 &&
        charCode != 189
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addJuristic() {
      this.formAdd.push({ juristicId: "" });
    },
    clickForm() {
      this.pageNow = 1;
      this.limit = 10;
    },
    resetForm() {
      this.form = {
        type: null,
        file: [],
      };
    },
    async deleteAllLeadScoring() {
      this.loadingSubmit = true;
      this.$store
        .dispatch("getAllLeadScoring")
        .then((res) => {
          if (res.status == 200) {
            if (typeof res.obj == "object") {
              var juristicId = res.obj.map((e) => e.juristicId);
              this.$store
                .dispatch("destroyLeadScoring", { juristicId: juristicId })
                .then((expected) => {
                  if (expected.status == 200) {
                    this.$message({
                      message: "ล้างข้อมูล สำเร็จ!",
                      type: "success",
                      duration: 4000,
                    });
                    this.dataLead = [];
                    this.totalNow = 0;
                    this.statusCalLead = false;
                    this.loadingSubmit = false;
                  }
                })
                .catch((e) => {
                  if (e.status == 422) {
                    this.$message({
                      message: "Bad Request",
                      type: "error",
                      duration: 4000,
                    });
                  }
                });
            }
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },
    async resetFormAdd() {
      // if (this.resultLead.length > 0) {
      // var juristicId = await this.resultLead.map((e) => e.juristicId);
      // await this.$store
      //   .dispatch("destroyLeadScoring", {
      //     juristicId: juristicId,
      //   })
      //   .then((res) => {
      //     if (res.status == 200) {
      //       this.$message({
      //         message: "ล้างข้อมูล สำเร็จ!",
      //         type: "success",
      //         duration: 4000,
      //       });
      //     }
      //   })
      //   .catch((err) => {
      //     if (err.status == 422) {
      //       this.$message({
      //         message: "Bad Request",
      //         type: "error",
      //         duration: 4000,
      //       });
      //     }
      //   });
      // }

      this.resultLead = [];
      this.formAdd = [
        {
          juristicId: "",
        },
      ];
      this.statusCalLead = false;
    },
    uploadDocuments(file, fileList) {
      let typeThis = file.raw.name.split(".");
      if (typeThis[1] == "xlsx" || typeThis[1] == "csv") {
        // check file size
        if (file.size < 25000000) {
          let obj = {
            name: file.name,
            file: file,
          };

          this.form.file.push(obj);
          // let indexFile = fileList.findIndex((a) => a.uid == file.uid);
          // if (typeThis[0] != "lead_scoring") {
          //   this.$message({
          //     message: "ชื่อไฟล์นำเข้าไม่ถูกต้อง",
          //     type: "error",
          //     duration: 4000,
          //   });
          //   fileList.splice(indexFile, 1);
          // } else {
          //   this.form.file.push(obj);
          // }
        } else {
          let index = fileList.findIndex((a) => a.uid == file.uid);
          fileList.splice(index, 1);
          this.$message({
            message: "กรุณาเลือกไฟล์ขนาดไม่เกิน 25mb",
            type: "error",
            duration: 4000,
          });
        }
      } else {
        let index = fileList.findIndex((a) => a.uid == file.uid);
        fileList.splice(index, 1);
        this.$message({
          message: "กรุณาเลือกไฟล์นามสกุลเป็น .CSV หรือ .XLSX เท่านั้น",
          type: "error",
          duration: 4000,
        });
      }
    },
    handleRemoveDocuments(file) {
      let index = this.form.file.findIndex((f) => f.file.uid == file.uid);
      this.form.file.splice(index, 1);
    },
    handleExceed() {
      this.$message.warning(`คุณสามารถแนบไฟล์ได้สูงสุด 1 ไฟล์`);
    },
    async importData(formData) {
      let res = await this.$store
        .dispatch("leadscoring/importLeadScoring", formData)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          if (error.status == 502) {
            return {
              status: 502,
              success: false,
              statusCode: 50002,
              data: "Bad GetWay",
              obj: null,
            };
          }
          return error.data;
        });
      return res;
    },
    async submitImportData() {
      // show alert pending
      this.hasQueue = true;

      this.loadingSubmit = true;
      var formData = new FormData();
      let typeThis = this.form.file[0].file.name.split(".");
      if (typeThis[1] == "csv") {
        formData.append(
          "file",
          new File(
            [this.form.file[0].file.raw],
            this.form.file[0].file.raw.name,
            { type: "text/csv" }
          )
        );
      } else {
        formData.append("file", this.form.file[0].file.raw);
      }
      HTTPUPLOAD.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      let checkStatusFinish = null;

      setTimeout(() => {
        this.loadingSubmit = false;
        this.form.file = [];
        this.fileList = [];
        this.scoringQueue();
      }, 500);

      // console.log("queue count: ", this.queueCount);

      // Import Lead Scoring
      // let res = await this.$store.dispatch(
      //   "leadscoring/importLeadScoring",
      //   formData
      // );

      let res = await this.importData(formData);

      if (res.success) {
        if (this.queueCount <= 1) {
          this.$message({
            message: "กำลังคำนวณ Lead Scroing",
            type: "warning",
            duration: 4000,
          });
        } else {
          this.$message({
            message: "กำลังรอ Queue Lead Scroing",
            type: "warning",
            duration: 4000,
          });
        }

        this.resultLead = res.obj;
        clearTimeout(checkStatusFinish);

        this.getLeadSocring();
        this.getQueue();
        this.form.file = [];
        this.fileList = [];
        return;
      } else {
        clearTimeout(checkStatusFinish);
        this.getQueue();

        let httpStatusCode = !!res.status ? res.status : 0;
        let statusCode = !!res.statusCode ? res.statusCode : 0;

        // handle error import
        var alertMessage = res.data;
        var alertType = "error";

        // extract message alert
        switch (httpStatusCode) {
          case 204:
            if (statusCode == 895474) {
              alertMessage = "กำลังรอ Queue Lead Scroing";
              alertType = "warning";
            } else {
              alertMessage = "ไฟล์หรือชื่อไฟล์ไม่ถูกต้อง";
            }
            break;
          case 400:
            alertMessage = "ไฟล์นำเข้าไม่ถูกต้อง";
            break;
          case 401:
            this.checkAuth();
            break;
          case 404:
            clearTimeout(checkStatusFinish);
            this.loadingSubmit = false;
            break;
          case 500:
            alertMessage = "Server ไม่พร้อมให้บริการ หรือกำลังมีปัญหาอยู่";
            break;
          case 502:
            alertMessage = "มีการสื่อสารผิดพลาดระหว่าง Server";
            break;
          default:
            alertMessage = res.data;
            break;
        }
      }

      this.$message({
        type: alertType,
        message: alertMessage,
        duration: 4000,
      });

      checkStatusFinish = setTimeout(() => {
        if (checkStatusFinish == false) {
          this.$message({
            message: "กำลัง Upload File",
            type: "warning",
            duration: 4000,
          });
        }
      }, 1000);
    },
    clearQueue() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`lead/clear/queue`)
        .then((res) => {
          if (res.data.success) {
            this.fetchUpload = false;
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    searchData() {
      this.pageNow = 1;
      this.limit = 10;
      let debounce = null;
      clearTimeout(debounce);
      debounce = setTimeout(() => {
        this.getLeadSocring();
      }, 500);
    },
    changePage(page, skip) {
      this.pageNow = page;
      this.getLeadSocring();
    },
    downloadTemplate() {
      this.loadingSubmit = true;
      let columnsTemplate = ["juristicId"];

      let csvContent = "";
      csvContent += [columnsTemplate.join(",")]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      var link = window.document.createElement("a");
      link.setAttribute(
        "href",
        "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent)
      );
      link.setAttribute("download", "lead_scoring.csv");
      link.click();
      this.$message({
        message: "Download Template ข้อมูลสำเร็จ",
        type: "success",
        duration: 4000,
      });
      setTimeout(() => {
        this.loadingSubmit = false;
      }, 200);
    },

    handleSizeChange(val) {
      this.pageNow = 1;
      this.limit = val;
      this.getLeadSocring();
    },
    submitCalLead() {
      this.loadingCalLead = true;
      this.loadingSubmit = true;
      let setJuristicId = [];
      this.formAdd.forEach((row) => {
        setJuristicId.push(row.juristicId);
      });

      let obj = {
        juristicId: setJuristicId,
      };

      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post(`lead/scoring`, obj)
        .then((res) => {
          if (res.data.success) {
            this.resultLead = res.data.obj;
            this.$message({
              message: "นำเข้าเลขนิติบุคคล สำเร็จ!",
              type: "success",
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else if (e.response.status == 422) {
              this.$message({
                message: "กรุณากรอกเลขนิติบุคคล!",
                type: "error",
                duration: 4000,
              });
              this.resultLead = [];
              this.formAdd = [
                {
                  juristicId: "",
                },
              ];
            } else {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loadingCalLead = false;
          this.loadingSubmit = false;
          this.statusCalLead = true;
        });
    },
    selectTypeImport() {
      if (this.form.type == 2) {
        this.getQueue();
        this.getLeadSocring();
      }
    },
    exportLeadScoring() {
      this.loadingSubmit = true;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`lead/scoring/export`)
        .then((res) => {
          if (res.data.success) {
            var file_path = this.urlBase + "excels/" + res.data.obj.filename;
            var a = document.createElement("A");
            a.href = file_path;
            a.download = file_path.substr(file_path.lastIndexOf("/") + 1);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            setTimeout(() => {
              HTTP.delete(`lead/scoring/export/${res.data.obj.filename}`);
            }, 1000);
            this.$message({
              message: "Export ข้อมูลสำเร็จ",
              type: "success",
              duration: 4000,
            });
          }
        })
        .catch((e) => {
          if (e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        })
        .finally(() => {
          this.loading = false;
          this.loadingSubmit = false;
        });
    },
  },
};
</script>
