<template>
  <div>
    <el-table
      class="table-normal animate__animated animate__fadeIn animate__slow"
      :data="dataLead"
      border
      style="width: 100%"
      v-loading="loading"
      element-loading-text="กำลังโหลดข้อมูล..."
      element-loading-spinner="el-icon-loading"
    >
      <template slot="empty">
        <p class="font-30 font-weight-500">ไม่พบข้อมูลที่คุณค้นหา</p>
      </template>
      <el-table-column
        prop="companyName"
        align="center"
        label="ชื่อบริษัท"
      ></el-table-column>
      <el-table-column prop="juristicId" align="center" label="เลขนิติบุคคล">
      </el-table-column>
      <!-- <el-table-column prop="businessSize" label="ขนาดธุรกิจ">
      </el-table-column> -->
      <el-table-column prop="gradeName" align="center" label="Lead Scoring">
        <template slot-scope="scope">
          <span
            :style="
              scope.row.gradeId == 0 &&
              scope.row.gradeName == 'ไม่พบเลขนิติบุคคลในระบบ DBD'
                ? 'color: #ED1C24;'
                : ''
            "
            >{{ scope.row.gradeName }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="gradeName"
        align="center"
        label="ที่ตั้งสถานประกอบการ"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.location }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="text-center mg-t-5">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="changePage"
        :current-page.sync="page"
        :page-sizes="[10, 30, 50, 100, 500]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalNow"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataLead: {
      type: Array,
      default() {
        return [];
      },
    },

    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },

    totalNow: {
      type: Number,
      default() {
        return 0;
      },
    },
    pageNow: {
      type: Number,
      default() {
        return 0;
      },
    },
    limit: {
      type: Number,
      default() {
        return 10;
      },
    },
  },
  data() {
    return {
      page: 0,
    };
  },
  mounted() {
    this.page = this.pageNow;
  },
  watch: {
    pageNow() {
      this.page = this.pageNow;
    },
  },
  methods: {
    changePage(page) {
      this.$emit("changePage", page);
    },
    handleSizeChange(page) {
      this.$emit("handleSizeChange", page);
    },
  },
};
</script>

<style></style>
